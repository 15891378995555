import { lazy } from "react";
import IntroContent from "../../content/IntroContent.json";
import WatercolorsContent from "../../content/Watercolors.json";
import NatureContent from "../../content/Nature.json";
import MonstersContent from "../../content/Monsters.json";
import SouvenirsContent from "../../content/SouvenirsContent.json";
import ContactContent from "../../content/ContactContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";

const Contact = lazy(() => import("../../components/ContactForm"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        type="right"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="intro.jpg"
        iconStyles={{"border": "4px white solid"}}
        id="intro"
      />      
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
        id="middleblock"
      />
      <ContentBlock
        type="left"
        title={WatercolorsContent.title}
        content={WatercolorsContent.text}   
        images={WatercolorsContent.images}     
        showNav={WatercolorsContent.showNav}     
        showFullscreenButton={WatercolorsContent.showFullscreenButton}     
        showPlayButton={WatercolorsContent.showPlayButton}     
        autoPlay={WatercolorsContent.autoPlay}     
        showThumbnails={WatercolorsContent.showThumbnails}     
        thumbnailPosition={WatercolorsContent.thumbnailPosition}     
        id="watercolors"
      />      
      <ContentBlock
        type="right"
        title={NatureContent.title}
        content={NatureContent.text}   
        images={NatureContent.images}     
        showNav={NatureContent.showNav}     
        showFullscreenButton={NatureContent.showFullscreenButton}     
        showPlayButton={NatureContent.showPlayButton}     
        autoPlay={NatureContent.autoPlay}     
        showThumbnails={NatureContent.showThumbnails}     
        thumbnailPosition={NatureContent.thumbnailPosition}     
        id="nature"
      />   
      <ContentBlock
        type="left"
        title={MonstersContent.title}
        content={MonstersContent.text}   
        images={MonstersContent.images}     
        showNav={MonstersContent.showNav}     
        showFullscreenButton={MonstersContent.showFullscreenButton}     
        showPlayButton={MonstersContent.showPlayButton}     
        autoPlay={MonstersContent.autoPlay}     
        showThumbnails={MonstersContent.showThumbnails}     
        thumbnailPosition={MonstersContent.thumbnailPosition}     
        id="monsters"
      />
      <ContentBlock
        type="right"
        title={SouvenirsContent.title}
        content={SouvenirsContent.text}        
        icon="souvenirs.jpg"
        iconStyles={{"border": "4px white solid"}}
        id="souvenirs"
      />
      <Contact
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
  );
};

export default Home;
