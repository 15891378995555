import { Row } from "antd";
import { Image } from "../../common/Image";
import Container from "../../common/Container";

import {
  NavLink,
  Extra,
  LogoContainer,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <Image src={src} width="35px" height="35px" />
      </a>
    );
  };

  return (
    <>      
      <Extra>
        <Container border={false}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem", flexWrap: "nowrap" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <Image
                  src="logo_no_borders.png"
                  aria-label="homepage"
                  width="101px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              {/* <SocialLink
                href="https://www.facebook.com/maria.chekunova"
                src="facebook.svg"
              /> */}
              <SocialLink
                href="https://www.instagram.com/aurella_art/"
                src="instagram.svg"
              />
              <SocialLink
                href="https://www.behance.net/aurella/"
                src="behance.svg"
              />
              {/* <SocialLink
                href="https://www.twitter.com/Mari_elfa"
                src="twitter.svg"
              /> */}
            </FooterContainer>
          </Row>

          <Row                        
            style={{ paddingTop: "2rem", textAlign: "center" }}
          >
            ALL RIGHTS RESERVED. Any unauthorized reprint or use of these images is strictly prohibited. None of the designs / illustrations may be reproduced, resold, or used for promotional / social media use without written consent from the artist ©️Aurella Art
          </Row>          
        </Container>
      </Extra>
    </>
  );
};

export default Footer;
