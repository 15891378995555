import React, { useEffect, useState } from 'react';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { Styles } from './styles/styles'

function App() {
  const [fullScreenTriggerElement, setFullScreenTriggerElement] = useState(null);

  useEffect(() => {
    document.addEventListener("fullscreenchange", (e: any) => {
      if (fullScreenTriggerElement) {
        setTimeout(() => {
          window.scrollTo({
            top: (fullScreenTriggerElement as HTMLDivElement).getBoundingClientRect().top + window.scrollY - 20, 
            behavior: 'smooth'
          });
        }, 100);
  
        setFullScreenTriggerElement(null);
      } else {
        setFullScreenTriggerElement(e.srcElement);
      }
    });    
  }, [fullScreenTriggerElement]);

  return (
    <React.Fragment>
      <Styles />
      <Header />
      <Home/>
      <Footer />    
    </React.Fragment>
  );
}

export default App;
